<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>현장관리</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<!-- <div class="panel-heading">
				<h6 class="panel-title">
					<i class="icon-search4 position-left"></i>
					<b>검색조건</b>
				</h6>
				<div class="heading-elements">
					<ul class="icons-list">
						<li><a @click="pannelHidden" data-action="collapse"></a></li>
					</ul>
				</div>
			</div> -->
			<div class="panel-body" style="width: 900px; margin: 20px 0px 0px auto">
				<form action="#" @submit.prevent="search">
					<div class="row">
						<div class="col-md-3" style="width: 280px">
							<div class="form-group">
								<label>{{ detailsFieldMap.siteNm }}</label>
								<input
									type="text"
									id="startInput"
									class="form-control"
									:placeholder="detailsFieldMap.siteNm"
									v-model="searchVM.siteNm"
									:maxlength="maxLength.siteNm"
								/>
							</div>
						</div>
						<div class="mb-10 text-right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<!-- Grid -->
		<div>
			<div class="mb-10 text-right">
				<button type="button" class="btn btn-labeled bg-teal mx-sm-1" @click="downloadExcel">
					<b><i class="icon-file-excel"></i></b>
					엑셀다운로드
				</button>
				<button type="button" class="btn btn-labeled bg-primary" @click="startCreate">
					<b><i class="icon-plus3"></i></b>
					추가
				</button>
			</div>

			<KendoGrid
				ref="grid"
				:auto-bind="true"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>

			<div class="mt-10 mb-15 text-right"></div>
		</div>

		<!-- Details -->
		<form id="detailsForm" action="#" class="form-horizontal form-validate-jquery" v-show="isEditMode || isCreateMode">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<h6 class="panel-title">
						<i class="icon-checkmark3 position-left"></i>
						<b>상세정보</b>
					</h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li><a @click="pannelHidden" data-action="collapse"></a></li>
						</ul>
					</div>
				</div>
				<div class="panel-body">
					<div class="row in-panel-body">
						<fieldset>
							<legend class="text-semibold">
								<i class="icon-cog3 position-left"></i>
								현장관리정보
							</legend>
							<div class="col-lg-6 creatDiv">
								<!-- 현장ID, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.siteId"
									required="true"
									v-if="isEditMode"
									:disabled="!isCreateMode"
									field="detailsItem.siteId"
									v-model="detailsItem.siteId"
									data-vv-name="detailsItem.siteId"
									v-validate="validationRule.detailsItem.siteId"
								></jarvis-field>
								<!-- 현장명 -->
								<jarvis-field
									:label="detailsFieldMap.siteNm"
									id="firstInputCreate"
									required="true"
									field="detailsItem.siteNm"
									v-model="detailsItem.siteNm"
									data-vv-name="detailsItem.siteNm"
									v-validate="validationRule.detailsItem.siteNm"
									:maxLength="maxLength.siteNm"
								></jarvis-field>
								<div class="col-lg-12">
									<!-- 공사기간 -->
									<div class="form-group">
										<label class="col-lg-3 control-label">{{ detailsFieldMap.workDate }}</label>
										<div class="row">
											<input type="date" class="form-control period col-lg-5" style="margin-left: 10px" v-model="detailsItem.workStrtDt" />
											<div class="col-lg-1">&nbsp;~</div>
											<input type="date" class="form-control period col-lg-5" style="margin-left: 5px" v-model="detailsItem.workEndDt" />
										</div>
									</div>
								</div>
								<div class="form-group itemWrapper" :class="errorAddr" style="margin-bottom: 15px">
									<label class="col-lg-3 control-label">
										{{ detailsFieldMap.baseAddr }}
										<span class="required">*</span>
									</label>
									<!-- 우편 번호 -->
									<input type="text" class="form-control col-lg-9 period" style="margin-left: 10px" v-model="detailsItem.zpcd" :disabled="true" />
									<button class="btn btn-primary" @click.prevent="searchAddr" style="margin-left: 10px; margin-right: 50px">
										<i class="fa fa-search"></i>
									</button>
								</div>
								<!-- 주소 -->
								<div class="form-group itemWrapper" :class="errorAddr">
									<label class="col-lg-3 control-label"></label>
									<div class="col-lg-9">
										<input id="baseAddress" type="text" class="form-control" v-model="detailsItem.baseAddr" :disabled="true" />
										<label class="msg"></label>
									</div>
								</div>
								<!-- 상세 주소 -->
								<div class="form-group">
									<label class="col-lg-3 control-label">{{ detailsFieldMap.dtlAddr }}</label>
									<div class="col-lg-9">
										<input
											id="detailAddress"
											type="text"
											class="form-control"
											ref="test"
											v-model="detailsItem.dtlAddr"
											v-validate="validationRule.detailsItem.dtlAddr"
											:maxLength="maxLength.dtl_addr"
										/>
									</div>
								</div>
								<!-- 등록일, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.regDtm"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.regDtm"
									:value="detailsItem.regDtm | toDisplayDateTime"
								></jarvis-field>
								<!-- 등록자, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.regUser"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.regUser"
									:value="detailsItem.regUser"
								></jarvis-field>
								<!-- 수정일, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.updDtm"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.updDtm"
									:value="detailsItem.updDtm | toDisplayDateTime"
								></jarvis-field>
								<!-- 수정자, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.updUser"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.updUser"
									:value="detailsItem.updUser"
								></jarvis-field>
							</div>

							<div class="col-lg-6" v-if="isEditMode">
								<!-- 연결된 안전관리자 -->
								<div class="from-group">
									<!-- <div style="margin-top: 20px; margin-bottom: 20px">
										<label class="col-lg-3 control-label">안전관리자</label>
										<input type="text" class="form-control" :value="detailsItem.userNm" :disabled="true" style="display: inline-block; width: 60%" />
										<button
											class="btn btn-primary"
											@click.prevent="showPopupSearch"
											data-toggle="modal"
											data-target="#siteSafetySupervisorsSearch"
											style="margin-left: 10px"
										>
											<i class="fa fa-search"></i>
										</button>
									</div> -->
									<h3 style="text-align: center">안전관리자 리스트</h3>
									<!-- 안전관리자 table -->
									<div class="table-wrapper" ref="tableWrapperLaw">
										<table class="table">
											<thead>
												<tr>
													<th>업체명</th>
													<th>성명</th>
													<th>휴대폰번호</th>
													<!-- <th></th> -->
												</tr>
											</thead>
											<tbody>
												<!-- <tr
													v-for="(row, index) in detailsItem.siteSafetySupervisors"
													:key="index"
													@click="clickSiteSafetySupervisorRow(index)"
													v-bind:class="{ active: activeSiteSafetySupervisorRow == index }"
												> -->
												<tr
													v-for="(row, index) in detailsItem.siteSafetySupervisors"
													:key="index"
													v-bind:class="{ active: activeSiteSafetySupervisorRow == index }"
												>
													<!-- <td v-model="row.companyNm">{{ row.companyNm }}</td>
													<td v-model="row.userNm">{{ row.userNm }}</td>
													<td v-model="row.hpNo">{{ row.hpNo }}</td> -->
													<td>{{ row.companyNm }}</td>
													<td style="text-align: center">{{ row.userNm }}</td>
													<td style="text-align: center">{{ replaceToPhoneFormat(row.hpNo) }}</td>
													<!-- <td>
														<button class="btn btn-xs btn-default" @click.stop="delSiteSafetySupervisorRow(index)">
															<i class="fa fa-minus"></i>
														</button>
													</td> -->
												</tr>
												<tr empty>
													<!-- <td colspan="4">추가된 안전관리자가 없습니다</td> -->
													<td colspan="3">추가된 안전관리자가 없습니다</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</fieldset>
					</div>
					<div class="text-right" v-if="!isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @click="createData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>
					<div class="text-right" v-if="isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-warning mx-sm-1" @click="deleteData">
							<b><i class="icon-minus3"></i></b>
							삭제
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @click="updateData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>

					<span v-if="debug">
						isCreateMode: {{ isCreateMode }}
						<br />
						isEditMode: {{ isEditMode }}
						<br />
						detailsItemOriginal: {{ detailsItemOriginal }}
						<br />
						detailsItem: {{ detailsItem }}
						<br />
						errors: {{ errors }}
					</span>
				</div>
			</div>
		</form>

		<!-- <div id="siteSafetySupervisorsSearch" class="modal fade">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<div class="modal-header">
						<button type="button" class="close" data-dismiss="modal">&times;</button>
						<h5 class="modal-title">안전관리자 검색</h5>
					</div>
					<div class="modal-body">
						<div class="search">
							<input
								type="search"
								class="form-control"
								placeholder="업체명 입력"
								v-model="searchVM.popupCompanyNm"
								@keyup.enter="popupSearch"
								:maxlength="maxLength.companyNm"
							/>
							<input
								type="search"
								class="form-control"
								placeholder="성명 입력"
								v-model="searchVM.popupUserNm"
								@keyup.enter="popupSearch"
								:maxlength="maxLength.siteSafetySupervisorNm"
							/>
							<input
								type="search"
								class="form-control"
								placeholder="휴대폰번호 입력"
								v-model="searchVM.popupHpNo"
								@keyup.enter="popupSearch"
								:maxlength="maxLength.hpNo"
							/>
							<button class="btn btn-primary" @click="popupSearch">
								<i class="fa fa-search"></i>
								검색
							</button>
						</div>

						<div class="table-wrapper" ref="tableWrapperSiteSafetySupervisor">
							<table class="table table-hover">
								<thead>
									<tr>
										<th>업체명</th>
										<th>성명</th>
										<th>휴대폰 번호</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(row, index) in tableDataPopup"
										:key="index"
										v-on:click="clickPopupRowSiteSafetySupervisor(row.companyId, row.companyNm, row.userId, row.userNm, row.hpNo)"
									>
										<td>{{ row.companyNm }}</td>
										<td>{{ row.userNm }}</td>
										<td style="text-align: center">{{ replaceToPhoneFormat(row.hpNo) }}</td>
									</tr>
									<tr empty>
										<td colspan="3">등록된 안전관리자가 없습니다</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import _ from 'lodash';
import JarvisField from '@/components/JarvisField';
import apiIndex from '../../api/index';
import moment from 'moment';

let axiosExtention; //eslint-disable-line no-unused-vars
const siteUrl = apiIndex.site;

//상세필드검증규칙
const detailsValidationRule = {
	common: {
		//공통
		siteNm: 'max:200|required',
		zpcd: 'max:10|numeric',
		base_addr: 'max:500',
		dtl_addr: 'max:500',
	},
	edit: {
		//수정
	},
	create: {
		//생성
	},
};

const maxLength = {
	siteNm: '200',
	dtl_addr: '500',
	companyNm: '100',
	siteSafetySupervisorNm: '50',
	hpNo: '11',
};

_.extend(detailsValidationRule.edit, detailsValidationRule.common);
_.extend(detailsValidationRule.create, detailsValidationRule.common);

export default {
	components: {
		'jarvis-field': JarvisField,
		KendoGrid: KendoGrid,
	},
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('sitePageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;
		this.detailsOptions.companyOptions = this.pageParam.companyOptions;
		axiosExtention = this.$jarvisExtention.axiosExtention;
		this.$validator.localize('ko', { attributes: this.$jarvisExtention.addKeyPrefix(this.detailsFieldMap, 'detailsItem.') });
	},
	mounted() {
		this.$nextTick(function () {
			this.focusFirstInput('startInput');
		});
	},
	data: () => ({
		pageParam: null,
		//API목록
		apiUrl: {
			excelApi: siteUrl.exlSite,
			pageListApi: siteUrl.inqSite,
			detailsApi: siteUrl.inqOneSite,
			inqPopupSiteSafetySupervisorApi: siteUrl.inqPopupSiteSafetySupervisor,
			inqSiteSupervisorByUserId: siteUrl.inqSiteSupervisorByUserId,
			delApi: siteUrl.delSite,
			updApi: siteUrl.updSite,
			insertApi: siteUrl.insSite,
		},
		//검색VM
		searchVM: {
			siteNm: '',
			popupCompanyNm: '',
			popupUserNm: '',
			popupHpNo: '',
		},
		//적용된 검색조건
		searchState: {},
		//그리드 설정
		gridColumns: [
			{ field: 'siteId', title: '현장ID', width: '10%', align: 'center' },
			{ field: 'siteNm', title: '현장명', width: '20%' },
			{
				field: 'workStrtDt',
				title: '공사시작일자',
				width: '10%',
				align: 'center',
				dataFormat: 'YYYY-MM-DD',
			},
			{
				field: 'workEndDt',
				title: '공사종료일자',
				width: '10%',
				align: 'center',
				dataFormat: 'YYYY-MM-DD',
			},
			{ field: 'baseAddr', title: '주소', width: '20%' },
			{ field: 'dtlAddr', title: '상세주소', width: '30%' },
		],
		//상세 select options
		detailsOptions: {
			companyOptions: '',
		},
		//상세필드명맵
		detailsFieldMap: {
			siteId: '현장ID',
			siteNm: '현장명',
			workDate: '공사기간:',
			workStrtDt: '',
			workEndDt: '',
			zpcd: '',
			baseAddr: '주소:',
			dtlAddr: '상세주소',
			companyId: '협력업체ID',
			regDtm: '등록일',
			regUser: '등록자',
			updDtm: '수정일',
			updUser: '수정자',
		},
		//상세편집 원본
		detailsItemOriginal: {},
		//상세편집 수정본
		detailsItem: {},
		baseAddrValue: '',
		tableDataPopup: [],
		activeCompanyRow: 0,
		activeSiteSafetySupervisorRow: 0,
		errorAddr: '',
		isValidSupervisor: false,
		maxLength,
		debug: false,
	}),
	computed: {
		isEditMode() {
			return !_.isEmpty(this.detailsItem) && !_.isEmpty(this.detailsItemOriginal);
		},
		isCreateMode() {
			return !_.isEmpty(this.detailsItem) && _.isEmpty(this.detailsItemOriginal);
		},
		validationRule() {
			var rule = null;
			if (this.isEditMode) {
				if (this.detailsItem.isChangePwd) {
					rule = $.extend(true /*deep*/, { pwd: 'required|min:4|max:100' }, detailsValidationRule.edit);
				} else {
					rule = detailsValidationRule.edit;
				}
			} else {
				rule = detailsValidationRule.create;
			}
			return { detailsItem: rule };
		},
	},
	watch: {
		detailsItem(newVal) {
			this.$validator.reset(); //Validation상태 초기화
			//details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal)) {
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
			}
		},
		baseAddrValue() {
			if (this.detailsItem.baseAddr != '') {
				this.errorAddr = '';
			}
		},
		isCreateMode() {
			let element = document.getElementsByClassName('creatDiv')[0];
			if (this.isCreateMode) {
				element.classList.add('col-lg-offset-3');
			} else {
				element.classList.remove('col-lg-offset-3');
			}
		},
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailsForm');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		replaceToPhoneFormat(hpNo) {
			return hpNo.replace(/(\d{2,3})(\d{3,4})(\d{4})/, '$1-$2-$3');
		},
		focusFirstInput(inputId) {
			document.getElementById(inputId).focus();
		},
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
		//검색적용
		search() {
			this.searchState = $.extend(true /*deep*/, {}, this.searchVM);
			this.loadGrid();
		},
		//그리드로드
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return $.extend(true /*deep*/, param, this.searchState || {});
		},
		//엑셀다운로드
		downloadExcel() {
			this.$axios({
				url: this.apiUrl.excelApi,
				method: 'POST',
				data: this.searchState,
				responseType: 'blob', // important
			})
				.then(response => {
					let date = new Date();
					// 저장 파일 명 생성
					let fileName = '현장관리_' + date.getFullYear() + ('0' + (1 + date.getMonth())).slice(-2) + ('0' + date.getDate()).slice(-2) + '.xlsx';
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		//그리드선택처리
		selectedRowItemChanged(selectedRowItem) {
			if (!_.isEmpty(selectedRowItem)) {
				//수정시작
				this.$axios
					.post(this.apiUrl.detailsApi, { siteId: selectedRowItem.siteId })
					.then(
						function (response) {
							this.detailsItemOriginal = $.extend(true /*deep*/, {}, response.data);

							response.data.workStrtDt = moment(response.data.workStrtDt).format('YYYY-MM-DD');
							response.data.workEndDt = moment(response.data.workEndDt).format('YYYY-MM-DD');

							this.errorAddr = '';

							/*response.data.workStrtDt.format('YYYY-MM-DD');*/
							this.detailsItem = $.extend(true /*deep*/, {}, response.data);
							setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			} else {
				//상세화면 닫기 (다시로드등으로 선택변경)
				this.closeDetails();
			}
		},
		//상세화면 닫기
		closeDetails() {
			this.detailsItemOriginal = {};
			this.detailsItem = {};
			this.errorAddr = '';
		},
		//데이터 추가 시작
		startCreate() {
			this.detailsItemOriginal = {};
			this.detailsItem = {
				siteId: '',
				siteNm: '',
				workStrtDt: moment().format('YYYY-MM-DD'),
				workEndDt: moment().format('YYYY-MM-DD'),
				zpcd: '',
				baseAddr: '',
				dtlAddr: '',
				latitude: null,
				longitude: null,
				siteSafetySupervisors: [],
			};
			this.errorAddr = '';
			setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
		},
		//신규저장
		createData() {
			this.$validator.validateAll().then(success => {
				if (this.detailsItem.baseAddr.length === 0) {
					this.errorAddr = 'reqAddr';
				} else {
					this.errorAddr = '';
				}
				if (this.errorAddr.length !== 0) {
					return;
				}
				if (success == false) {
					return;
				}
				if (this.detailsItem.workStrtDt > this.detailsItem.workEndDt) {
					this.$set(this.detailsItem, 'workStrtDt', this.detailsItem.workStrtDt);
					this.detailsItem.workEndDt = null;
					alert('공사기간의 종료 날짜는 시작 날짜보다 나중이어야 합니다');
					return;
				}
				this.detailsItem.workStrtDt = moment(this.detailsItem.workStrtDt).format('YYYYMMDD');
				this.detailsItem.workEndDt = moment(this.detailsItem.workEndDt).format('YYYYMMDD');
				this.$axios
					.post(this.apiUrl.insertApi, this.detailsItem)
					.then(
						function () {
							this.loadGrid();
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			});
		},
		//수정저장
		updateData() {
			this.$validator.validateAll().then(success => {
				if (this.detailsItem.baseAddr.length === 0) {
					this.errorAddr = 'reqAddr';
				} else {
					this.errorAddr = '';
				}
				if (this.errorAddr.length !== 0) {
					return;
				}
				if (success == false) {
					return;
				}
				if (this.detailsItem.workStrtDt > this.detailsItem.workEndDt) {
					this.$set(this.detailsItem, 'workStrtDt', this.detailsItem.workStrtDt);
					this.detailsItem.workEndDt = null;
					alert('공사기간의 종료 날짜는 시작 날짜보다 나중이어야 합니다');
					return;
				}

				if (confirm('수정한 데이터를 저장하시겠습니까?')) {
					this.detailsItem.workStrtDt = moment(this.detailsItem.workStrtDt).format('YYYYMMDD');
					this.detailsItem.workEndDt = moment(this.detailsItem.workEndDt).format('YYYYMMDD');

					this.$axios
						.post(this.apiUrl.updApi, this.detailsItem)
						.then(
							function (response) {
								response;
								this.loadGrid();
							}.bind(this),
						)
						.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
				}
			});
		},
		//삭제
		deleteData() {
			if (!confirm('해당 데이터를 영구적으로 삭제하시겠습니까?')) {
				return;
			}
			this.$axios
				.post(this.apiUrl.delApi, { siteId: this.detailsItem.siteId })
				.then(
					function (response) {
						response;
						this.loadGrid();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
		// 안전관리자 검색 팝업 노출
		// showPopupSearch() {
		// 	this.searchVM.popupCompanyNm = '';
		// 	this.searchVM.popupUserNm = '';
		// 	this.searchVM.popupHpNo = '';
		// 	this.getPopuptList();
		// },
		// 팝업 내 검색 버튼 클릭 시 처리
		// popupSearch() {
		// 	this.getPopuptList();
		// },
		// 테이블에 리스트 불러오기
		// getPopuptList() {
		// 	const cursor = this;
		// 	this.$axios
		// 		.post(this.apiUrl.inqPopupSiteSafetySupervisorApi, {
		// 			popupCompanyNm: this.searchVM.popupCompanyNm,
		// 			popupUserNm: this.searchVM.popupUserNm,
		// 			popupHpNo: this.searchVM.popupHpNo,
		// 		})
		// 		.then(
		// 			function (r) {
		// 				cursor.tableDataPopup = r.data;
		// 				cursor.$refs.tableWrapperSiteSafetySupervisor.scrollTo({ top: 0, behavior: 'smooth' });
		// 				//this.$refs.tableWrapperTarget.scrollTo({ top:0, behavior: "smooth" });
		// 			}.bind(this),
		// 		)
		// 		.catch(axiosExtention.buildErrorHandler());
		// },
		// 팝업 창에서 안전관리자 선택 시 행 추가
		// async clickPopupRowSiteSafetySupervisor(companyId, companyNm, userId, userNm, hpNo) {
		// 	var isDuplicate = false; // 리스트 중복 확인

		// 	await this.$axios
		// 		.post(this.apiUrl.inqSiteSupervisorByUserId, { userId: userId })
		// 		.then(
		// 			function (response) {
		// 				if (response.data < 1) {
		// 					this.isValidSupervisor = true;
		// 				} else {
		// 					this.isValidSupervisor = false;
		// 				}
		// 			}.bind(this),
		// 		)
		// 		.catch(axiosExtention.buildErrorHandler());

		// 	if (this.isValidSupervisor == false) {
		// 		this.detailsItem.siteSafetySupervisors.forEach(function (el, index) {
		// 			index;
		// 			if (el.userId.trim() == userId && el.companyId == companyId && el.hpNo == hpNo) {
		// 				isDuplicate = true;
		// 				return;
		// 			}
		// 		});
		// 		if (isDuplicate) {
		// 			alert('해당 안전관리자는 이미 현재 현장에 추가되어 있습니다.');
		// 			return;
		// 		}
		// 		alert('해당 안전관리자는 이미 다른 현장에 등록되어 있습니다. 다른 안전관리자를 선택해주세요.');
		// 		return;
		// 	}

		// 	this.detailsItem.siteSafetySupervisors.push({ companyId: companyId, companyNm: companyNm, userId: userId, userNm: userNm, hpNo: hpNo });
		// 	$('#siteSafetySupervisorsSearch').modal('hide');
		// },
		// // 안전관리자 행 클릭 시 event
		// clickSiteSafetySupervisorRow(index) {
		// 	this.activeSiteSafetySupervisorRow = index;
		// },
		// // 안전관리자 행 삭제
		// delSiteSafetySupervisorRow(index) {
		// 	this.detailsItem.siteSafetySupervisors.splice(index, 1);

		// 	if (this.detailsItem.siteSafetySupervisors.length) {
		// 		if (!this.detailsItem.siteSafetySupervisors[index]) index -= 1;
		// 	}
		// 	this.activeSiteSafetySupervisorRow = index;
		// },
		searchAddr() {
			const cursor = this;
			new window.daum.Postcode({
				oncomplete: data => {
					// 각 주소의 노출 규칙에 따라 주소를 조합한다.
					// 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
					var addr = ''; // 주소 변수
					var extraAddr = ''; // 참고항목 변수

					addr = data.roadAddress;

					// 법정동명이 있을 경우 추가한다. (법정리는 제외)
					// 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
					if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
						extraAddr += data.bname;
					}
					// 건물명이 있고, 공동주택일 경우 추가한다.
					if (data.buildingName !== '' && data.apartment === 'Y') {
						extraAddr += extraAddr !== '' ? ', ' + data.buildingName : data.buildingName;
					}
					// 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
					if (extraAddr !== '') {
						extraAddr = ' (' + extraAddr + ')';
					}
					// 조합된 참고항목을 해당 필드에 넣는다.
					// this.detailsItem.dtlAddr = extraAddr;

					// 우편번호와 주소 정보를 해당 필드에 넣는다.
					cursor.detailsItem.zpcd = data.zonecode;
					cursor.detailsItem.baseAddr = addr + ' ' + extraAddr;

					cursor.baseAddrValue = cursor.detailsItem.baseAddr;
					// 주소-좌표 변환 객체를 생성
					let geocoder = new window.daum.maps.services.Geocoder();
					// 주소로 좌표를 검색
					geocoder.addressSearch(data.address, function (result, status) {
						// 정상적으로 검색이 완료됐으면,
						if (status == window.daum.maps.services.Status.OK) {
							cursor.detailsItem.longitude = result[0].road_address.x;
							cursor.detailsItem.latitude = result[0].road_address.y;
						}
					});
					// 커서를 상세주소 필드로 이동한다.
					document.getElementById('detailAddress').focus();
				},
			}).open();
		},
	},
};
</script>

<style scoped>
.required {
	color: red;
}

#detailsForm .table-wrapper {
	max-height: 200px;
	overflow-y: auto;
}
.modal .table-wrapper {
	max-height: 300px;
	overflow-y: auto;
}
.modal .table tbody tr:hover {
	cursor: pointer;
}

.table {
	table-layout: fixed;
}
.table th {
	background: #eee;
	vertical-align: middle !important;
	font-weight: bold;
	text-align: center;
}

.panel-body {
	text-align: center;
}

.panel-body .table th:first-child {
	width: 250px;
	text-align: center;
}

/* .panel-body .table th:last-child {
	width: 5.5rem;
	text-align: center;
} */
.table td {
	vertical-align: middle;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 1px;
	text-align: left;
}

.panel-body .table td:last-child {
	padding: 0px;
}

.table [empty] {
	display: none;
}
.table [empty] td {
	padding: 1.2em !important;
	font-size: 1.2em;
	text-align: center;
}
.table [empty]:first-child:last-child {
	display: table-row;
}

.modal .search {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}
[type='search'] {
	display: inline-block;
	width: 180px;
	margin-right: 5px;
}

#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}
#rootVM > .panel .panel-body .form-group label {
	margin-top: 5px;
}
#rootVM > .panel .panel-body .form-group input {
	max-width: 200px;
	margin-left: 15px;
}
.period {
	display: inline-block;
	width: 30%;
}

.itemWrapper {
	display: flex;
	margin-bottom: 20px;
}
.itemWrapper > label {
	margin-top: 7px;
}

.itemWrapper > div > label {
	margin-top: 7px;
	margin-bottom: 7px;
	display: block;
	color: #f44336;
	position: relative;
	padding-left: 26px;
	font-size: 13px;
	display: none;
}

.itemWrapper .msg:before {
	content: '\ed63';
	font-family: 'icomoon';
	font-size: 16px;
	position: absolute;
	top: -2px;
	left: 0;
}

.in-panel-body {
	display: inline-block;
	width: 1200px;
}

.itemWrapper.reqAddr > label {
	color: #d84315;
}
.itemWrapper.reqAddr .msg {
	display: block;
}
.itemWrapper.reqAddr input {
	border: 1px solid #d84315;
}
.itemWrapper.reqAddr .msg:after {
	content: '주소항목은 필수 정보입니다.';
}
</style>
